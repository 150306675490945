<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a">订单查询</li>
         
                   
                </div>
                 <div  class="item">  
                <li class="a" ><router-link to="/index5/xiaoxi55" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">技术专员<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                              <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码" autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                               <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <!-- <div class="search" style="padding:20px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input2">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input3">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input4">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div> -->
            <div class="content">
                    <p style="margin:20px 0">投诉订单</p>
                    
                          <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="wechatname"
                    label="用户名"
                   >
                    </el-table-column>
                
   
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="phone"
                    label="电话"
                  >
                    </el-table-column>

                    
                

                     
                         <el-table-column
                        prop="comment" 
                    label="投诉原因"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
           
  <el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>

     
       
                     <el-table-column
                      
                    label="是否处理"
                        :show-overflow-tooltip="true" 
                  >
                    <template slot-scope="scope">
                            <p>{{scope.row.status | capitalize}}</p>
                        </template>
                    </el-table-column>
                   <el-table-column
                      
                    label="操作"
                        :show-overflow-tooltip="true" 
                  >
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="success" size="small">处理</el-button>
                        </template>
                    </el-table-column>
                </el-table>
   <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="pagination1.page_index"
      :page-sizes="pagination1.page_sizes"
      :page-size="pagination1.page_size"
      :layout="pagination1.layout"
      :total='pagination1.total'
      style="margin-top:20px">
                 </el-pagination>
                
            </div>
  </div>
</template>

<script>
export default {
          filters: {
  capitalize: function (value) {
  if(value==0){
      return '未处理'
    
  }else {
      return '已处理'
  }
  }
  
},
 data(){
        return{
              pagination1:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            result:'',
                pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

                value2: '',
            input1:'',
             input2:'',
              input3:'',
               input4:'',
            datal1:[],
            datall:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },

      
       methods:{
             handleClick(row){
               console.log()
               
  this.$axios({
                        method: "post",
                        url: "/s/backend/api/handle_complain",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                              "id": row.id
                        }
                        }) .then(res=>{
                        
                    if(res.data.code == 0 ){
                          this.$axios.get("/s/backend/api/complain_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
    this.pagination1.total =res.data.count
        })
        .catch(function(){
           
        })

                    }
          
  
        })
        .catch(function(){
           
        })
           },
  handleSizeChange1(val) {
this.pagination1.page_size=val

this.$axios.get("/s/backend/api/complain_list", {
      params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination1.page_index,
       limit :val,
       roleid :10  }
        })

     .then(res=>{
    
           this.data1 = res.data.data
          
  
        })
        .catch(function(){
           
        })


    
 


            
                 
      },
      handleCurrentChange1(val) {
       this.pagination1.page_index=val
     

 this.$axios.get("/s/backend/api/complain_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination1.page_size,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            
        })
        .catch(function(){
           
        })

  
        
      },

              ck1(){
          
this.input1=''
this.input2=''
this.input3=''
this.input4=''
this.value2 = ''
   this.$axios.get("/s/backend/api/complain_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
             
              
}
})
  .then(res=>{
         
           this.data1= res.data.data
        })
        .catch(function(){
           
        })
        },
        ck2(){
            
            console.log(this.value2)
                  this.$axios.get("/s/backend/api/complain_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
           
                orderid:this.input2,
                phone:this.input4,
                name:this.input1,

                starttime:this.value2[0],
                endtime:this.value2[1]
}
})
  .then(res=>{
         
           this.data1= res.data.data
        })
        .catch(function(){
           
        })
        },
          a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }else{
                                        this.$message({
                    message:res.data.msg,
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
       },
        mounted(){

                 
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })
 










   this.$axios.get("/s/backend/api/complain_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
    this.pagination1.total =res.data.count
        })
        .catch(function(){
           
        })

  this.$axios({
                        method: "get",
                        url: "/s/backend/api/cw_fp",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':10
                        },
                     
                        }) .then(res=>{
                             this.datal1=res.data.data
                        })

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>